import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import '../styles/index.css';

export const ItemStyle = { display: 'inline', marginLeft: 10, marginRight: 10, fontFamily: 'Piedra' };

export const pages: { [key: string]: string } = {
  HOME: '/',
  // BAND: '/band',
  IMPRINT: '/imprint'
};

const NavigationSpacer = (props: { }) => {
  const [toggle, setToggle] = React.useState(false);
  const animatedMenuWrapperStyle = useSpring<React.CSSProperties>({
    color: '#436e7a',
    marginBottom: 50
  });
  const animatedLogoStyle = useSpring<React.CSSProperties>({ maxHeight: (toggle ? 10 : 25) + 'vh' });

  const handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    setToggle(winScroll > 100 ? true : false);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <animated.div style={{ pointerEvents: 'none', marginBottom: 100 }}>
      <animated.div
        style={{
          width: 'calc(100vw - 100px)',
          paddingLeft: 50,
          paddingRight: 50,
          top: 0,
          ...animatedMenuWrapperStyle,
          fontSize: '1.6em'
        }}
      >
        <div style={{ width: '100%', textAlign: 'center', marginTop: 25 }}>
          <animated.div
            style={{ maxWidth: '75%', ...animatedLogoStyle, height: 'calc(100vw / 3)' }}
          />
          <animated.div
            style={{
              ...animatedMenuWrapperStyle,
            }}
          >
          </animated.div>
        </div>
      </animated.div>
    </animated.div>
)};

export default NavigationSpacer;
