import * as React from 'react';
import type { HeadFC } from 'gatsby';
import '../styles/index.css';
import ProfilePicture from '../components/ProfilePicture';
import NavigationSpacer from '../components/NavigationSpacer';
import FooterSpacer from '../components/FooterSpacer';

const Band = () => {
  return (
    <main style={{ width: '100%', height: '100%' }}>
      <NavigationSpacer />
      <div style={{ textAlign: 'center' }}>
        <ProfilePicture url="/pexels-alena-darmel-7715852.jpg" name="Andi" description="" />
        <ProfilePicture url="/pexels-alena-darmel-7715852.jpg" name="Annika" description="" />
      </div>
      <div style={{ textAlign: 'center' }}>
        <ProfilePicture url="/pexels-alena-darmel-7715852.jpg" name="Max" description="" />
        <ProfilePicture url="/pexels-alena-darmel-7715852.jpg" name="Patrick" description="" />
      </div>
      <FooterSpacer />
    </main>
  );
};

export default Band;

export const Head: HeadFC = () => <title>Band</title>;
