import * as React from 'react';

const ProfilePicture = (props: { url: string; name: string; description: string }) => {
  const { url } = props;
  const { name } = props;
  const { description } = props;
  
  return (
    <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
      <img style={{ margin: 50, height: 300, width: 300, borderRadius: '90%' }} src={url} />
      <div style={{ display: 'inline-block' }}>{name}</div>
      <div style={{ display: 'inline-block' }}>{description}</div>
    </div>
  );
};

export default ProfilePicture;
